.survey__text {
  text-align: center;
  padding: 30px 20px 10px 20px;
  span {
    font-size: 60px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.97;
    letter-spacing: normal;
    text-align: center;
    color: #222;
  }
  label {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #89919d;
  }
}

.title__bar {
  margin: 30px 0px 20px 0px;
  padding: 5px 10px;
  background-color: #f8f8f8;

  label {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222;
  }
}
.option_label_name {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
}
.table__header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  span {
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222;
    display: block;
    white-space: nowrap;
    width: 71px;
  }
}

// .table__header {
//   span:nth-child(1) {
//     width: 71px;
//   }
//   span:nth-child(2) {
//     width: 36px;
//   }
//   span:nth-child(3) {
//     width: 31px;
//   }

//   span:nth-child(4) {
//     width: 51px;
//   }
//   span:nth-child(5) {
//     width: 71px;
//   }
// }

.radio__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  //   width: 249px;

  label {
    width: 71px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px;
  }
}

.comunity__text {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
  text-align: center;

  span {
    font-size: 14px;
    color: #222;
  }
}
.me__box {
  width: 32px;
  padding: 2px 0px;
  border-radius: 8px;
  border: solid 1px #275aff;
  background-image: linear-gradient(to bottom, #a7bbf9, #275aff);
  display: inline-block;
  margin-left: 10px;
  text-align: left;

  span {
    font-size: 9px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: block;
  }
}
.vs__text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222;
}
