h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
.ant-form-item-label {
  padding-bottom: 0px !important;
}

.ant-form-item {
  margin-bottom: 0px;
}
.box_shadow {
  box-shadow: 0 5px 12px rgb(88 42 251 / 12%) !important;
}
.form_label {
  font-weight: 700;
  font-size: 16px;
  height: auto;
}
.login__wrapper {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
}

.login__bg__img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  color: white;
  max-width: 513px;
  box-sizing: border-box;
  position: relative;
}
.login__bg__img::after {
  content: '';
  display: block;
  position: absolute;
  inset: 0px;
  background: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 767px) {
  .login__bg__img {
    display: none;
  }
}

.login__form {
  padding: 30px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.form__div {
  /* width: 50%; */
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  height: 100px;
  border-radius: 8px;
  background-color: #89919d;
}

body::-webkit-scrollbar-button {
  display: none;
}

.mini-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.mini-scroll::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: transparent;
}

.mini-scroll::-webkit-scrollbar-thumb {
  height: 100px;
  border-radius: 3px;
  background-color: #89919d;
}

.mini-scroll::-webkit-scrollbar-button {
  display: none;
}

@supports not selector(::-webkit-scrollbar) {
  html {
    scrollbar-width: thin;
    scrollbar-color: #89919d #e7e7e7;
  }
}

@media screen and (max-width: 767px) {
  .form__div {
    width: 90%;
  }
}

/* bilal */

.img__modal .ant-modal-content {
  padding: 0px;
  position: relative;
}
.community_tab .ant-tabs-tab {
  padding: 5px 20px 5px 8px !important;
}
.home__competitor__tab .ant-tabs-nav {
  margin-bottom: 5px;
  padding: 0px;
}
.c_price_value {
  margin: 0px;
  font-size: 54px;
  color: inherit;
  font-weight: normal;
}
.c_rate_title {
  text-transform: uppercase;
  color: #817e7e;
  font-size: 14px;
}
.c_price__text_box {
  position: relative;
}
.c_doller_sign {
  position: absolute;
  top: 2px;
  left: -15px;
  font-size: 24px !important;
  font-weight: 300 !important;
}
.c_float_points {
  font-size: 28px !important;
  font-weight: 300 !important;
}
.c_avg_market {
  font-size: 16px !important;
  color: #ad4e00;
}
.ant-select-item.ant-select-item-option {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
}
:where(.css-dev-only-do-not-override-h89p1r).ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0px;
}
.marker__popup {
  padding: 20px 15px;
  box-shadow: 0 5px 12px rgba(52, 51, 55, 0.12) !important;
  background: #000;
  color: #fff;
  box-sizing: border-box;
  width: max-content;
  border-radius: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  text-align: center;
  min-width: 190px;
}

/* marker */
.marker_price_value {
  margin: 0px !important;
  font-size: 32px !important;
  color: inherit;
  font-weight: normal !important;
}
.marker_rate_title {
  text-transform: uppercase;
  color: #817e7e;
  font-size: 14px;
}
.marker_price__text_box {
  position: relative;
}
.marker_doller_sign {
  position: absolute;
  top: 0px;
  left: -10px;
  font-size: 16px !important;
  font-weight: 300 !important;
}
.marker_float_points {
  font-size: 16px !important;
  font-weight: 300 !important;
}
:where(.css-dev-only-do-not-override-td4l8m).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #333;
}
:where(.css-dev-only-do-not-override-td4l8m).ant-tabs .ant-tabs-ink-bar {
  background: #333;
}
:where(.css-dev-only-do-not-override-td4l8m).ant-tabs {
  color: #a8a5a5;
}

.p_healing_active_tab {
  border: 1px solid #ddd;
  box-shadow: 4px 5px 5px #ddd;
  border-radius: 50px;
}
.healing_select_input .ant-select-selector {
  padding: 0px !important;
  height: max-content !important;
  color: #989696 !important;
}
.healing__active {
  border: 1px solid #ddd;
  box-shadow: 4px 5px 5px #ddd;
  border-radius: 50px;
  padding: 5px 30px 0px 30px;
}
.p_healing_table thead > tr {
  background: #fbfbfb;
  box-shadow: 1px 2px 4px #ddd;
  border-top: 2px solid #ddd;
}
.p_healing_table {
  border-top: 1px solid #ddd;
}
:where(.css-dev-only-do-not-override-td4l8m).ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 0;
  font-size: 18px;
}
.rent_roll__table table td {
  border: 1px solid #eee;
  margin: 0px;
}
.rent_roll__table table input {
  border: 0px;
}
.rent_roll__table .ant-select-selector {
  border: 0px !important;
}

.home_community_table td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ant-select .ant-select-selection-placeholder {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
}
:where(.css-dev-only-do-not-override-pw15b3).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #d7d7d7;
}

.hide-scrollbar {
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  visibility: hidden;
}

.p-button-link {
  padding: 0;
  width: auto !important;
  height: auto;
  border: 0;
  display: block;
  box-shadow: none;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
}

.widget-actions:not(.ant-dropdown-open) {
  visibility: hidden;
}

.black-mask {
  background: rgba(0, 0, 0, 0.9) !important;
}
