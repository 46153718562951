:root {
  --font-regular: AvenirLTPro-Medium;
  --font-bold: AvenirLTPro-Heavy;

  --text-primary: #222222;
  --white: #fff;
  --error-red: #ff4d4f;
  --text-secondary: #6d6d6d;
  --text-tertiary: #1d252d;
  --positive-green: #0ab776;
  --positive-green-light: #e2f5ee;
  --light-green: #e7fceb;
  --neutral-purple: #614ac8;
  --neutral-purple-light: #ded9f4;
  --competitor-purple: #5f5fff;
  --negative-orange: #fa7550;
  --inactive-orange: #e8a947;
  --light-orange: rgba(250, 117, 90, 0.09);
  --dark-orange: #ff5829;
  --background-gray: #f9f9f9;
  --box-shadow-gray: #d8d8d8;
  --box-shadow-gray-dark: rgba(0, 0, 0, 0.1);
  --box-shadow-pink: #d7dce5;
  --light-rose-pink: #fce7e7;
  --lighter-gray: #f1f1f1;
  --light-gray: #eeeeee;
  --gray-dark: #89919d;
  --gray-medium: #f0f1f3;
  --gray-lighter: #f5f6f7;
  --line-gray: #d7d7d7;
  --faded-blue: #ebf0ff;
  --link-blue: #1f58b0;
  --dark-blue: #1f58b0;
  --active-blue: #007aff;
  --button-blue: #275aff;
  --email-blue: #2791ff;
  --background-light-blue: #e1f0ff;
  --feather-blue: #eff3ff;
  --light-blue: #f5f8ff;
  --deep-blue: #102a61;
  --pale-blue: #f2f5ff;
  --pale-yellow: #fefad6;
  --faded-pink: #fdefff;
  --hot-pink: #e847b6;
  --orchid-pink: #f57de5;
  --sidebar-purple-dark: #272540;
  --sidebar-purple-light: #444077;
  --sidebar-selector-purple-dark: #21203c;
  --sidebar-selector-purple-light: #2a285c;
  --sidebar-selector-shadow: rgba(0, 0, 0, 0.27);
  --sidebar-selector-border: rgba(255, 255, 255, 0.16);
  --background: #f8f8f8;
  --input-border: #40a9ff;

  --community-marker-purple-dark: #1f1c44;
  --community-marker-purple-light: #444077;
  --community-marker-blue: #007aff;
  --community-marker-orange: #fa8c16;

  --background-gray-light: #fafafa;
}