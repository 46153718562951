.black_bar {
  width: 1000px;
  height: auto;
  padding: 8px 107px 9px 112px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 30px;
}
.black_bar_title {

  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.6);
}
.doller_sign {
  margin: 5px 0 16px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  position: absolute;
  top: 0px;
  left: -12px;
}
.amount_text {
  margin: 14px 70px 12px 0;
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;

  span {
    font-size: 20px;
  }
}

.market_avg {
  margin: 16px 60px 0 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  white-space: nowrap;

  span {
    // color: #fa7550;
    margin-left: 5px;
  }
}

.rating_text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;

  span {
    font-size: 42px;
  }
}

// white bar
.white_bar {
  width: 998px;
  height: auto;
  padding: 8px 130px 9px 148px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.white_title {
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #222;
  text-transform: uppercase;

  span {
    color: #89919d;
    margin-left: 3px;
  }
}
.down_trigger {
  position: absolute;
  top: -16px;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 22px;
  border-radius: 7px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab_style [class~="ant-tabs-tab-btn"] {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #89919d;
}
.tab_style [class~="ant-tabs-tab-active"] {
  div {
    color: #222 !important;
  }
}
.tab_style [class~="ant-tabs-ink-bar"] {
  background: #222;
}

// table
.header_title {
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #1d252d;
}
.home_community_table {
  thead {
  }
}
