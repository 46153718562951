@font-face {
    font-family: 'AvenirLTPro-Heavy';
    src: url("webFonts/AvenirLTProHeavy/font.woff2") format('woff2'),
    url("webFonts/AvenirLTProHeavy/font.woff") format('woff');
}

@font-face {
    font-family: "AvenirLTPro-Medium";
    src: url("webFonts/AvenirLTProMedium/font.woff2") format("woff2"),
    url("webFonts/AvenirLTProMedium/font.woff") format("woff");
}